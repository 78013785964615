import { Col, Container, Row } from "react-bootstrap";
import Tile from "../../components/tile/Tile";
import { useTranslation } from "react-i18next";
import Tickets from "./tickets/Tickets";
import { useAuthStatus } from "../../authentication/UseAuthentication";
import { AuthenticationMethod } from "../../enums/AuthenticationMethod";

export function Helpdesk() {
    const { t } = useTranslation();
    const { authenticationMethod } = useAuthStatus();

    const title = authenticationMethod === AuthenticationMethod.MSAL
        ? t("it.title.internal")
        : t("it.title.external");

    const description = authenticationMethod === AuthenticationMethod.MSAL
        ? t("it.description.internal")
        : t("it.description.external");
    
    return (
        <Container fluid>
            <Row className="mb-3">
                <Col>
                    <h1>{title}</h1>
                    <p className="lead">{description}</p>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={6} lg={3} className="mb-3">
                    <Tile   title={t("it.new.title")}
                            description={t("it.new.description")}
                            link="/it/ticket"
                            image="/img/tiles/create-case.jpg"
                            />
                </Col>
                {
                    process.env.REACT_APP_FEATURE_FLAG_IT_KB_SUPPORT === "true" &&
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Tile   title={t("it.kb.title")}
                                description={t("it.kb.description")}
                                link="/it/kb"
                                image="/img/tiles/kb_tile.jpg"
                                />
                    </Col>
                }
            </Row>
            <Row className="mt-1">
                <Col>
                    <Tickets />
                </Col>
            </Row>
        </Container>
    );
}