import Home from '../home/Home';
import { Helpdesk as HRHelpdesk } from '../helpdesk/hr/Helpdesk';
import { Helpdesk as ITHelpdesk } from '../helpdesk/it/Helpdesk';
import { NewTicket as NewITTicket } from '../helpdesk/it/form/NewTicket';
import { NewTicket as NewHRTicket } from '../helpdesk/hr/form/NewTicket';
import { Routes, Route } from 'react-router-dom';
import { Ticket as ITTicket } from '../helpdesk/it/ticket/Ticket';
import { Ticket as HRTicket } from '../helpdesk/hr/ticket/Ticket';
import KnowledgeBase from '../kb/KnowledgeBase';
import { Article } from '../kb/article/Article';
import { Feedback } from '../helpdesk/it/feedback/Feedback';
import { Escalation } from '../helpdesk/it/feedback/escalation/Escalation';
import { Overview as ApprovalsOverview } from '../approvals/Overview';
import { Request as ApprovalRequest } from '../approvals/request/Request';
import OnboardingForm from '../hr/onboarding/OnboardingForm';
import LAPS from '../laps/LAPS';
import Laptop from '../laps/laptop/Laptop';
import { useAuthStatus } from '../authentication/UseAuthentication';
import { AuthenticationMethod } from '../enums/AuthenticationMethod';

export const ProtectedRoutes = () => {
    const { authenticationMethod } = useAuthStatus();
    
    return (
        <Routes>
            <Route path='/' Component={Home} />

            {
                process.env.REACT_APP_FEATURE_FLAG_APPROVALS_SUPPORT === "true" &&
                authenticationMethod === AuthenticationMethod.MSAL &&
                <>
                    <Route path='/requests' Component={ApprovalsOverview} />
                    <Route path='/request/:requestId' Component={ApprovalRequest} />
                </>
            }
            
            {
                process.env.REACT_APP_FEATURE_FLAG_IT_SUPPORT === "true" &&
                <>
                    <Route path='/it' Component={ITHelpdesk} />
                    <Route path='/it/ticket' Component={NewITTicket} />
                    <Route path='/it/ticket/:ticketId' Component={ITTicket} />
                    <Route path='/it/ticket/:ticketId/feedback' Component={Feedback} />
                    <Route path='/it/ticket/:ticketId/escalate' Component={Escalation} />
                </>
            }

            {
                process.env.REACT_APP_FEATURE_FLAG_IT_KB_SUPPORT === "true" &&
                <>
                    <Route path='/it/kb' Component={KnowledgeBase} />
                    <Route path='/it/kb/:articleId' Component={Article} />
                </>
            }
            
            {
                process.env.REACT_APP_FEATURE_FLAG_PEOPLE_SUPPORT === "true" &&
                authenticationMethod === AuthenticationMethod.MSAL &&
                <>
                    <Route path='/hr' Component={HRHelpdesk} />
                    <Route path='/hr/ticket' Component={NewHRTicket} />
                    <Route path='/hr/ticket/:ticketId' Component={HRTicket} />
                </>
            }

            {
                process.env.REACT_APP_FEATURE_FLAG_ONBOARDING_SUPPORT === "true" &&
                authenticationMethod === AuthenticationMethod.MSAL &&
                <>
                    <Route path="/onboarding/:id" Component={OnboardingForm} />
                </>
            }

            {
                process.env.REACT_APP_FEATURE_FLAG_LAPS_SUPPORT === "true" &&
                authenticationMethod === AuthenticationMethod.MSAL &&
                <>
                    <Route path="/laps" Component={LAPS} />
                    <Route path="/laps/:deviceId" Component={Laptop} />
                </>
            }
        </Routes>
    );
};