import { useMsal } from "@azure/msal-react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Authenticated = () => {
    const { accounts } = useMsal();
    const { t } = useTranslation();

    const account = accounts[0];
    const text = t('authentication.authorise.success', { name: account.name });

    return (
        <Alert variant="success">
            {text}
        </Alert>
    );
}

export default Authenticated;