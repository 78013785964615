import { PublicClientApplication } from "@azure/msal-browser";

const clientId = process.env.REACT_APP_AZURE_AD_APP_CLIENT_ID!;
const tenantId = process.env.REACT_APP_AZURE_AD_TENANT_ID!;
const instance = process.env.REACT_APP_AZURE_AD_INSTANCE!;
const authority = `${instance}${tenantId}`;

export const scope = `api://${process.env.REACT_APP_AZURE_AD_APP_CLIENT_ID}/${process.env.REACT_APP_AZURE_AD_API_SCOPE}`;

const msalInstance = new PublicClientApplication({
    auth: {
        clientId: clientId,
        authority: authority,
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
});

export default msalInstance;