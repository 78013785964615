import { jwtDecode } from "jwt-decode";
import { getJwt } from "./Authentication";
import IJWT from "../models/IJWT";

const getClaim = (claims: any, claim: string) : string | null => {
    const keys = Object.keys(claims);

    if (keys.filter(k => k.startsWith("http") && k.endsWith(claim)).length > 0) {
        const key = keys.filter(k => k.startsWith("http") && k.endsWith(claim))[0];
        return claims[key];
    }

    if (keys.includes(claim)) {
        return claims[claim];
    }

    return null;
};

const ReadToken = (): IJWT => {
    const token = getJwt();
    const decodedToken = jwtDecode<any>(token!);

    const expiryClaim = getClaim(decodedToken, "exp"),
        nameClaim = getClaim(decodedToken, "displayname"),
        emailClaim = getClaim(decodedToken, "emailaddress");

    if (!emailClaim || !nameClaim || !expiryClaim) {
        throw new Error("Claim emailaddress or exp not provided.");
    }

    const claims: IJWT = {
        email: emailClaim,
        displayName: nameClaim,
        exp: Number(expiryClaim)
    };

    return claims;
};

export default ReadToken;