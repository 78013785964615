import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import Status from "../enums/Status";
import { useEffect, useState } from "react";
import accountService from "../services/accountService";

export default function WelcomeText() {
    const { t } = useTranslation();
    const { instance, accounts } = useMsal();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [name, setName] = useState<string>("");

    useEffect(() => {
        instance.initialize()
            .then(async () => {
                const account = await accountService.getAccount();    
                setName(account.givenName ?? account.displayName);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, [accounts, instance]);

    return (
        <h3>
            {
                status === Status.LOAD_SUCCESS && name
                    ? t("home.subtitleWithName", { name })
                    : t("home.subtitleWithoutName")
            }
        </h3>
    );
}