import { BrowserRouter, useLocation } from 'react-router-dom';
import { ProtectedRoutes } from './Routes';
import Authorise from '../authentication/authorise/Authorise';
import Navbar from '../components/navbar/Navbar';
import QuickComms from '../comms/quick-comms/QuickComms';
import { useAuthStatus } from '../authentication/UseAuthentication';

const AuthenticatedApp = () => {
    const location = useLocation();

    if (location.pathname === '/quick-comms') {
        const queryParams = new URLSearchParams(location.search);
        const ticketId = Number(queryParams.get('ticketId'));

        return <QuickComms ticketId={ticketId} />;
    }

    return (
        <div>
            <Navbar />
            <main role="main">
                <ProtectedRoutes />
            </main>
        </div>
    );
};

export default function App() {
    const { isAuthenticated } = useAuthStatus();
    
    return (
        <BrowserRouter>
            {
                isAuthenticated
                    ? <AuthenticatedApp />
                    : <Authorise />
            }
        </BrowserRouter>
    );
}