import { getAuthMethod, getJwt } from "../authentication/Authentication";
import IGroup from "../models/groups/IGroup";
import IUser from "../models/user/IUser";
import CreateGraphClient, { getAccessToken } from "./MicrosoftGraphClient";
import networkService from "./networkService";
import { AuthenticationMethod } from "../enums/AuthenticationMethod";
import ReadToken from "../authentication/ReadToken";

class AccountService {

    public async getAccount() : Promise<IUser> {
        const authenticationMethod = getAuthMethod();

        if (authenticationMethod === AuthenticationMethod.MSAL) {
            const accessToken = await getAccessToken();
            const client = CreateGraphClient(accessToken!);
    
            try {
                return await client.me() as IUser;
            } catch (error) {
                throw error;
            }
        } else if (authenticationMethod === AuthenticationMethod.JWT) {
            const claims = ReadToken();
            
            return {
                displayName: claims.displayName,
                userPrincipalName: claims.email,
                mail: claims.email
            };
        } else {
            throw new Error("Authentication method not supported");
        }
    }

    public async getProfilePicture(): Promise<string> {
        const authenticationMethod = getAuthMethod();
        if (authenticationMethod !== AuthenticationMethod.MSAL) {
            throw new Error("Authentication method not supported");
        }
        
        const accessToken = await getAccessToken();
        const client = CreateGraphClient(accessToken!);

        try {
            return await client.myPhoto() as string;
        } catch (error) {
            throw error;
        }
    }

    public async getGroupMemberships(): Promise<IGroup[]> {
        const authenticationMethod = getAuthMethod();
        if (authenticationMethod !== AuthenticationMethod.MSAL) {
            throw new Error("Authentication method not supported");
        }
        
        const accessToken = await getAccessToken();
        const client = CreateGraphClient(accessToken!);

        try {
            return await client.myMemberships("microsoft.graph.group") as IGroup[];
        } catch (error) {
            throw error;
        }
    }

    /**
     * @description Check if the current user is an approver
     */
    async isApprover() {
        const authenticationMethod = getAuthMethod();
        if (authenticationMethod !== AuthenticationMethod.MSAL) {
            throw new Error("Authentication method not supported");
        }
        
        const result = await networkService.get<boolean>("user/approver");

        if (result.isSuccessful) {
            return result.data as boolean;
        }

        throw result.data;
    }

    /**
     * @description Check if the current user is able to view LAPS
     */
    async isLAPSUser() {
        const authenticationMethod = getAuthMethod();
        if (authenticationMethod !== AuthenticationMethod.MSAL) {
            throw new Error("Authentication method not supported");
        }
        
        const result = await networkService.get<boolean>("user/laps-user");

        if (result.isSuccessful) {
            return result.data as boolean;
        }

        throw result.data;
    }
}

const accountService = new AccountService();
export default accountService;