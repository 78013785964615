import { useMsal } from '@azure/msal-react';
import { useState, useEffect } from 'react';
import { getAuthMethod, getJwt, jwtLogout } from './Authentication';
import isTokenExpired from './IsTokenExpired';
import { AuthenticationMethod } from '../enums/AuthenticationMethod';

const useIsAuthenticated = () => {
    const { accounts } = useMsal();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [authMethod, setAuthMethod] = useState<AuthenticationMethod>(null!);

    useEffect(() => {
        const checkAuth = () => {
            const method = localStorage.getItem('authMethod') as AuthenticationMethod;
            setAuthMethod(method);
        };

        checkAuth();

        window.addEventListener('storage', checkAuth);

        return () => window.removeEventListener('storage', checkAuth);
    }, []);
    
    
    useEffect(() => {
        switch (authMethod) {
            case AuthenticationMethod.MSAL:
                setIsAuthenticated(accounts.length > 0);
                break;
            case AuthenticationMethod.JWT:
                const token = getJwt();
                
                if (token && !isTokenExpired(token)) {
                    setIsAuthenticated(true);
                    break;
                }
                
                setIsAuthenticated(false);
                jwtLogout();

                break;
            case AuthenticationMethod.NONE:
            default:
                setIsAuthenticated(false);
                break;
        }
    }, [accounts, authMethod]);

    return isAuthenticated;
};

const useAuthMethod = () => {
    const [authMethod, setAuthMethod] = useState<AuthenticationMethod>(null!);

    useEffect(() => {
        const method = getAuthMethod();
        setAuthMethod(method);

        const handleStorageChange = () => {
            const method = getAuthMethod();
            setAuthMethod(method);
        };

        window.addEventListener('storage', handleStorageChange);

        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    return authMethod;
};

const useAuthStatus = () => {
    const isAuthenticated = useIsAuthenticated();
    const authenticationMethod = useAuthMethod();

    return { isAuthenticated, authenticationMethod };
};

export { useAuthStatus };