import IAuthentication from "../models/authentication/IAuthentication";
import networkService from "./networkService";

/**
 * @description Service for handling non-MSAL authentication requests
 */
class AuthenticationService {
    
    /**
     * @description Requests an OTP code to be sent to the user's email
     * @param {string} name The display name of the user
     * @param {string} emailAddress The email address to send the code to
     */
    public async sendCode(name: string, emailAddress: string): Promise<boolean> {
        const result = await networkService.post("/authentication/send-otp", {
            emailAddress,
            displayName: name
        });

        return result.isSuccessful;
    }
    
    /**
     * @description Verifies the OTP code sent to the user's email
     * @param {IAuthentication} dto The authentication object containing the email and OTP code
     * @returns {string} The JWT token
     */
    public async verifyCode(dto: IAuthentication): Promise<string> {
        const result = await networkService.post<IAuthentication, string>("/authentication", dto);
        if (result.isSuccessful) {
            return result.data as string;
        } else {
            throw new Error("Failed to authenticate");
        }
    }

}

const authenticationService = new AuthenticationService();
export default authenticationService;