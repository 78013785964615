import { jwtDecode } from "jwt-decode";

interface JwtPayload {
    exp: number;
}

const isTokenExpired = (token: string): boolean => {
    if (!token) return true;

    const { exp } = jwtDecode<JwtPayload>(token);
    if (!exp) return true;

    const currentTime = Date.now() / 1000;
    return exp < currentTime;
};

export default isTokenExpired;