import { useMsal } from "@azure/msal-react";
import IAuthenticateComponentProps from "../IAuthenticateComponentProps";
import { useTranslation } from "react-i18next";
import AuthenticationStatus from "../../../enums/AuthenticationStatus";
import styles from "./AuthenticateLanding.module.scss";
import { Link } from "react-router-dom";
import { Icon } from "../../../components/icon/Icon";

export default function AuthenticateLanding(props: IAuthenticateComponentProps) {
    const { instance } = useMsal();
    const { t } = useTranslation();

    const handleMicrosoftLogin = async () => {
        try {
            await instance.loginRedirect();
        } catch (_) {
            props.setStatus(AuthenticationStatus.AUTHENTICATION_ERROR);
        }
    };

    const btnImgUrl = `${process.env.PUBLIC_URL}/img/ms-symbollockup_signin_dark.svg`;

    return (
        <div className="d-block">
            <div className="mb-3">
                <h3 className="h4">
                    {t('authentication.authorise.headers.staff')}
                </h3>
                <p>
                    {t('authentication.authorise.descriptions.staff')}
                </p>
                <img
                    src={btnImgUrl}
                    onClick={handleMicrosoftLogin}
                    className={styles.button}
                    alt={t('authentication.authorise.login')} />
            </div>
            <div className="mb-3">
                <h3 className="h4">
                    {t('authentication.authorise.headers.other')}
                </h3>
                <p>
                    {t('authentication.authorise.descriptions.other')}
                </p>
                <Link to="/login/other" className="btn btn-primary">
                    <Icon iconName="EnvelopeOpen" endMargin={2} />
                    {t('authentication.authorise.buttons.other')}
                </Link>
            </div>
        </div>
    );
}