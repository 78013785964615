import { Col, Row } from "react-bootstrap";
import LinkTile from "./LinkTile";
import { useTranslation } from "react-i18next";
import { useAuthStatus } from "../../authentication/UseAuthentication";
import { AuthenticationMethod } from "../../enums/AuthenticationMethod";

export default function SupportTiles() {
    const { t } = useTranslation();
    const { authenticationMethod } = useAuthStatus();
    
    return (
        <Row>
            <Col>
                <Row>
                    <h4 className="mb-1">{t("home.sections.support.title")}</h4>
                    <p className="lead">
                        {t("home.sections.support.subtitle")}
                    </p>
                </Row>
                <Row>
                    {
                        process.env.REACT_APP_FEATURE_FLAG_PEOPLE_SUPPORT === "true" &&
                        authenticationMethod === AuthenticationMethod.MSAL &&
                        <LinkTile   titleKey="hr.title"
                                    descriptionKey="hr.description"
                                    link="/hr"
                                    image="/img/tiles/hr_tile.jpg"
                                    />
                    }
                    
                    {
                        process.env.REACT_APP_FEATURE_FLAG_IT_SUPPORT === "true" &&
                        authenticationMethod === AuthenticationMethod.MSAL &&
                        <LinkTile   titleKey="it.title.internal"
                                    descriptionKey="it.description.internal"
                                    link="/it"
                                    image="/img/tiles/it_tile.jpg"
                                    />
                    }
                    
                    {
                        process.env.REACT_APP_FEATURE_FLAG_IT_SUPPORT === "true" &&
                        authenticationMethod === AuthenticationMethod.JWT &&
                        <LinkTile   titleKey="it.title.external"
                                    descriptionKey="it.description.external"
                                    link="/it"
                                    image="/img/tiles/fm24_tile.jpg"
                                    />
                    }
                </Row>
            </Col>
        </Row>
    );
}