import { useState } from "react";
import FloatingTextField from "../../../components/formFields/floatingText/FloatingTextField";
import FormFieldValidation from "../../../enums/FormFieldValidation";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import IAuthentication from "../../../models/authentication/IAuthentication";
import newAuthentication from "./NewAuthentication";
import { ILoginValidation, newValidation, validate } from "./Validation";
import Status from "../../../enums/Status";
import authenticationService from "../../../services/authenticationService";
import { jwtLogin } from "../../Authentication";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { AuthenticateFormFieldValidation } from "./Validation";

export default function EmailLogin() {
    const { t } = useTranslation();
    const navigation = useNavigate();

    const [form, setForm] = useState<IAuthentication>(newAuthentication());
    const [showOtp, setShowOtp] = useState<boolean>(false);
    const [validation, setValidation] = useState<ILoginValidation>(newValidation());
    const [status, setStatus] = useState<Status>(Status.NEUTRAL);

    const handleChange = (key: string, value: string) => setForm({ ...form, [key]: value });

    const handleSubmit = () => {
        const validation = validate(form.displayName, form.emailAddress, showOtp, form.otp);
        setValidation(validation);
        
        if (Object.values(validation).some((v) => v !== FormFieldValidation.VALID)) {
            return;
        }

        if (!showOtp) {
            submitEmail(form.displayName, form.emailAddress, setStatus, setShowOtp);
        } else {
            submitOtp(form, setStatus, navigation);
        }
    };

    const isDisabled = [Status.LOADING, Status.LOAD_SUCCESS].includes(status);

    const emailValidation = validation.emailAddress === AuthenticateFormFieldValidation.UNSUPPORTED_EMAIL_DOMAIN
        ? FormFieldValidation.INVALID
        : validation.emailAddress;

    const emailValidationMessage = validation.emailAddress === AuthenticateFormFieldValidation.UNSUPPORTED_EMAIL_DOMAIN
        ? t("authentication.authorise.fields.email.microsoftDomainValidation")
        : t("authentication.authorise.fields.email.validation");

    return (
        <div>
            {
                !showOtp &&
                <>
                    <p dangerouslySetInnerHTML={{ __html: t("authentication.authorise.leadingText.emailIntroduction") }} />
                    <div className="mb-3">
                        <FloatingTextField
                            disabled={isDisabled}
                            label={t("authentication.authorise.fields.name.label")}
                            onChange={(value) => handleChange("displayName", value)}
                            placeholder={t("authentication.authorise.fields.name.placeholder")}
                            type="text"
                            validationMessage={t("authentication.authorise.fields.name.validation")}
                            value={form.displayName}
                            validation={validation.displayName}
                            bottomMargin={0}
                        />
                        <span className="small text-muted">
                            {t("authentication.authorise.fields.name.subtleText")}
                        </span>
                    </div>
                    <div className="mb-3">
                        <FloatingTextField
                            disabled={isDisabled}
                            label={t("authentication.authorise.fields.email.label")}
                            onChange={(value) => handleChange("emailAddress", value)}
                            placeholder={t("authentication.authorise.fields.email.placeholder")}
                            type="email"
                            validationMessage={emailValidationMessage}
                            value={form.emailAddress}
                            validation={emailValidation}
                            bottomMargin={0}
                        />
                        <span className="small text-muted">
                            {t("authentication.authorise.fields.email.subtleText")}
                        </span>
                    </div>
                </>
            }
            {
                showOtp &&
                <div>
                    <p dangerouslySetInnerHTML={{ __html: t("authentication.authorise.leadingText.otp", { email: form.emailAddress }) }} />
                    <FloatingTextField
                        disabled={isDisabled}
                        label={t("authentication.authorise.fields.otp.label")}
                        onChange={(value) => handleChange("otp", value)}
                        placeholder={t("authentication.authorise.fields.otp.placeholder")}
                        type="text"
                        validationMessage={t("authentication.authorise.fields.otp.validation")}
                        value={form.otp!}
                        validation={validation.otp}
                    />
                </div>
            }
            {
                status === Status.LOAD_ERROR &&
                <p className="text-danger">{t("authentication.authorise.error")}</p>
            }
            <Container>
                <Row className="justify-content-between">
                    <Col className="text-start ps-md-0">
                        <Link to="/" className="btn btn-secondary">
                            {t('general.back')}
                        </Link>
                    </Col>
                    <Col className="text-end pe-md-0">
                        <Button type="button" variant="primary" disabled={isDisabled} onClick={handleSubmit}>
                            {
                                isDisabled &&
                                <Spinner
                                    animation="border"
                                    size="sm"
                                    className="me-2"
                                    />
                            }
                            {t('general.submit')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

async function submitEmail(
    name: string,
    email: string,
    setStatus: (status: Status) => void,
    setShowOtp: (showOtp: boolean) => void
) {
    setStatus(Status.LOADING);
    setShowOtp(false);
    
    try {
        const result = await authenticationService.sendCode(name, email);
       
        if (!result) {
            setStatus(Status.LOAD_ERROR);
            return;
        }

        setStatus(Status.NEUTRAL);
        setShowOtp(true);
    } catch (e) {
        setStatus(Status.LOAD_ERROR);
    }
}

async function submitOtp(
    form: IAuthentication,
    setStatus: (status: Status) => void,
    navigate: NavigateFunction
) {
    setStatus(Status.LOADING);
    
    try {
        const result = await authenticationService.verifyCode(form);
        
        if (!result) {
            setStatus(Status.LOAD_ERROR);
            return;
        }

        jwtLogin(result);
        setStatus(Status.LOAD_SUCCESS);

        setTimeout(() => navigate("/"), 500);
    } catch (e) {
        setStatus(Status.LOAD_ERROR);
    }
}