import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LinkTile from "./LinkTile";
import { useEffect, useState } from "react";
import Status from "../../enums/Status";
import accountService from "../../services/accountService";
import { getAuthMethod } from "../../authentication/Authentication";
import { AuthenticationMethod } from "../../enums/AuthenticationMethod";

export default function ActionTiles() {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [shouldShowApprovals, setShouldShowApprovals] = useState<boolean>(false);
    const [shouldShowLAPS, setShouldShowLAPS] = useState<boolean>(false);

    useEffect(() => {
        fetchTileVisibility(setShouldShowApprovals, setShouldShowLAPS)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, []);

    if ([Status.LOADING, Status.LOAD_ERROR].includes(status) ||
        (!shouldShowApprovals && !shouldShowLAPS)
    ) {
        return <></>;
    }

    return (
        <Row>
            <Col>
                <Row>
                    <h4 className="mb-1">{t("home.sections.actions.title")}</h4>
                    <p className="lead">
                        {t("home.sections.actions.subtitle")}
                    </p>
                </Row>
                <Row>
                    {
                        shouldShowApprovals &&
                        <LinkTile   titleKey="approval.titles.singular"
                                    descriptionKey="approval.description"
                                    link="/requests"
                                    image="/img/tiles/approval_tile.jpg"
                                    />
                    }
                    {
                        shouldShowLAPS &&
                        <LinkTile   titleKey="laps.title.short"
                                    descriptionKey="laps.description"
                                    link="/laps"
                                    image="/img/tiles/laps_tile.webp"
                                    />
                    }
                </Row>
            </Col>
        </Row>
    );
}

async function fetchTileVisibility(
    setShouldShowApprovals: (value: boolean) => void,
    setShouldShowLAPS: (value: boolean) => void
) {
    try {
        const authenticationMethod = getAuthMethod();

        const approvals = 
            process.env.REACT_APP_FEATURE_FLAG_APPROVALS_SUPPORT === "true" &&
            authenticationMethod === AuthenticationMethod.MSAL &&
            await accountService.isApprover();

        setShouldShowApprovals(approvals);

        const laps = 
            process.env.REACT_APP_FEATURE_FLAG_LAPS_SUPPORT === "true" && 
            authenticationMethod === AuthenticationMethod.MSAL &&
            await accountService.isLAPSUser();

        setShouldShowLAPS(laps);
    } catch (e) {
        console.error(e);
    }
}