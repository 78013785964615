import { useMsal } from "@azure/msal-react";
import Status from "../../../enums/Status";
import { useEffect, useState } from "react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { Dropdown } from "react-bootstrap";
import IUser from "../../../models/user/IUser";
import styles from "./Account.module.scss";
import { useTranslation } from "react-i18next";
import accountService from "../../../services/accountService";
import { jwtLogout } from "../../../authentication/Authentication";
import { useAuthStatus } from "../../../authentication/UseAuthentication";
import { AuthenticationMethod } from "../../../enums/AuthenticationMethod";

const AccountDetails = ({ account, photo }: { account: IUser, photo: string }) => (
    <div className="d-flex align-items-center">
        {
            photo &&
            <img
                alt={account.displayName}
                src={`data:image/*;base64,${photo}`}
                className={`rounded-circle me-2 ${styles.photo}`}
                />
        }
        <div className="d-block text-start small">
            <span className="d-block fw-bold">{account.displayName}</span>
            <span className="d-block">{account.mail}</span>
        </div>
    </div>
);

export default function Account() {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const { authenticationMethod } = useAuthStatus();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [account, setAccount] = useState<IUser>();
    const [photo, setPhoto] = useState<string>("");

    useEffect(() => {
        if (authenticationMethod === AuthenticationMethod.MSAL) {
            initialiseMsal(instance, setAccount, setPhoto, setStatus);
        } else {
            getAccountDetails(setAccount, setPhoto)
                .then(() => setStatus(Status.LOAD_SUCCESS))
                .catch(() => setStatus(Status.LOAD_ERROR));
        }
    }, [authenticationMethod, instance]);

    const handleLogout = async () => {
        if (authenticationMethod === AuthenticationMethod.MSAL) {
            await instance.logout();
        } else if (authenticationMethod === AuthenticationMethod.JWT) {
            jwtLogout();
        }
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="outline-light" className={styles.button}>
                {
                    status !== Status.LOAD_SUCCESS ? 
                        t("account.account") :
                        <AccountDetails account={account!} photo={photo} />
                }
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>{t("account.logout")}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

const initialiseMsal = async (
    msalInstance: IPublicClientApplication,
    setAccount: (account: IUser) => void,
    setPhoto: (photo: string) => void,
    setStatus: (status: Status) => void
) => {
    try {
        await msalInstance.initialize();
        await getAccountDetails(setAccount, setPhoto);

        setStatus(Status.LOAD_SUCCESS);
    } catch (e) {
        setStatus(Status.LOAD_ERROR);
    }
}

const getAccountDetails = async (
    setAccount: (account: IUser) => void,
    setPhoto: (photo: string) => void
) => {
    const account = await accountService.getAccount();
    setAccount(account);

    try {
        const photo = await accountService.getProfilePicture();
        setPhoto(photo);
    } catch (e) {
        setPhoto("");
    }
};