import { AuthenticationMethod } from "../enums/AuthenticationMethod";

const dispatchStorageEvent = () => window.dispatchEvent(new Event('storage'));

const getAuthMethod = () => localStorage.getItem('authMethod') as AuthenticationMethod;

const setAuthMethod = (method: AuthenticationMethod) => {
    localStorage.setItem('authMethod', method);
    dispatchStorageEvent();
};

const clearAuthMethod = () => {
    localStorage.removeItem('authMethod');
    dispatchStorageEvent();
};

const getJwt = () => localStorage.getItem('authToken');

const setJwt = (jwt: string) => {
    localStorage.setItem('authToken', jwt);
    dispatchStorageEvent();
};

const clearJwt = () => {
    localStorage.removeItem('authToken');
    dispatchStorageEvent();
};

const jwtLogin = (jwt: string) => {
    setJwt(jwt);
    setAuthMethod(AuthenticationMethod.JWT);
    dispatchStorageEvent();
};

const jwtLogout = () => {
    clearJwt();
    clearAuthMethod();
    dispatchStorageEvent();
};

export { getAuthMethod, setAuthMethod, clearAuthMethod, getJwt, clearJwt, jwtLogin, jwtLogout };